<template>
  <woot-button
    v-tooltip.left="$t(`SIDEBAR.PROFILE_SETTINGS`)"
    variant="link"
    class="items-center flex rounded-lg w-full"
    @click="handleClick"
  >
    <div
      class="flex items-center flex-row w-full"
      :class="{ 'justify-center': !isSidebarOpen }"
    >
      <thumbnail
        :src="currentUser.avatar_url"
        :username="currentUser.name"
        :status="statusOfAgent"
        should-show-status-always
        :class="{ 'mr-2': isSidebarOpen }"
        :size="isSidebarOpen ? '32px' : '24px'"
      />
      {{ isSidebarOpen ? currentUser.name : '' }}
    </div>
  </woot-button>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../../widgets/Thumbnail.vue';

export default {
  components: {
    Thumbnail,
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserAvailability: 'getCurrentUserAvailability',
    }),
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
  },
  methods: {
    handleClick() {
      this.$emit('toggle-menu');
    },
  },
};
</script>
