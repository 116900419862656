var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.$t(`SIDEBAR.SETTINGS`)),expression:"$t(`SIDEBAR.SETTINGS`)",modifiers:{"right":true}}],staticClass:"text-slate-700 dark:text-slate-100 font-medium w-10 h-10 flex my-1 items-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative",class:{
      'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50': isActive,
      'w-full px-2': _vm.isSidebarOpen,
      'justify-center': !_vm.isSidebarOpen,
    },attrs:{"href":href,"rel":undefined,"target":undefined},on:{"click":navigate}},[_c('fluent-icon',{class:{
        'text-woot-500': isActive,
        'min-w-[1rem] mr-1.5': _vm.isSidebarOpen,
      },attrs:{"icon":"settings"}}),_vm._v("\n    "+_vm._s(_vm.isSidebarOpen ? _vm.$t('SIDEBAR.SETTINGS') : '')+"\n  ")],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }